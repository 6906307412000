<template>
    <div>
        <div id="bg"></div>
        <h1>房产管理系统</h1>
        <div class="sub-main-w3">
            <a-spin :spinning="loading">
                <div class="form">
                    <h2>登录
                        <i class="fa fa-long-arrow-down"></i>
                    </h2>
                    <div class="form-style-agile">
                        <label>
                            <i class="fa fa-user"></i>
                            账号
                        </label>
                        <input placeholder="请输入登录账号" name="Name" type="text" v-model="form.account" required="">
                    </div>
                    <div class="form-style-agile">
                        <label>
                            <i class="fa fa-unlock-alt"></i>
                            密码
                        </label>
                        <input placeholder="请输入登录密码" name="Password" type="password" v-model="form.pwd" required="">
                    </div>
                    <input type="button" value="立即登录" @click="submit()">
                </div>
            </a-spin>
        </div>
    </div>
</template>
<script>
import md5 from 'js-md5'
import store from '@/store'
import { createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('user')
export default {
    data () {
        return {
            loading: false,
            form: {
                tokenType: 'pwd',
                secret: '55e21febfdc2496f94ff63625a656f3d',
                account: '',
                pwd: ''
            }
        }
    },
    methods: {
        ...mapActions(['Login']),
        submit () {
            if (this.form.account === '') {
                this.$message.error('请输入登录账号')
                return
            }
            if (this.form.pwd === '') {
                this.$message.error('请输入登录密码')
                return
            }
            console.log(this.form.pwd)
            let params = JSON.parse(JSON.stringify(this.form))
            params.pwd = md5(params.pwd)
            this.login(params)
        },
        login (params) {
            // this.loading = true
            const { Login } = this
            Login(params)
                .then(res => this.loginSuccess(res))
                .catch(error => this.loginFaild(error))
                .finally(_ => { this.loading = false })
        },
        loginSuccess (res) {
            if (res.status === 0) {
                this.$router.push({ path: '/' })
            } else{
                this.$message.error(res.msg)
            }
        },
        loginFaild (error) {
            this.$message.error(error)
            return
        }
    }
}
</script>
<style scoped>
    nav.horizontal ul li {
        display: inline-block;
    }
    body {
        font-family: 'Open Sans', sans-serif;
        font-size: 100%;
        background: #5985a8;
    }
    h1 {
        font-size: 2.0em;
        color: #fff;
        letter-spacing: 3px;
        text-align: center;
        font-style: italic;
        padding: 2vw 1vw;
        font-family: 'Josefin Sans', sans-serif;
    }
    h1 span, h2 i {
        color: #f7296f;
    }
    h2 {
        color: #fff;
        font-size: 22px;
        font-weight: 500;
        letter-spacing: 1px;
        text-transform: capitalize;
        margin-bottom: 1em;
    }
    .sub-main-w3 {
        display: -webkit-flex;
        display: -webkit-box;
        display: -moz-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-pack: center;
        -moz-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
    }
    .sub-main-w3 .form {
        max-width: 450px;
        margin: 0 5vw;
        background: rgba(10, 10, 10, 0.17);
        padding: 3.5vw;
        box-sizing: border-box;
        display: -webkit-flex;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        border-bottom: 8px solid #75696e;
        border-radius: 30px 30px 50px 50px;
    }
    .form-style-agile {
        margin-bottom: 1.5em;
        flex-basis: 100%;
        -webkit-flex-basis: 100%;
    }
    .sub-main-w3 label {
        font-size: 14px;
        color: #fff;
        display: inline-block;
        font-weight: 500;
        margin-bottom: 12px;
        text-transform: capitalize;
        letter-spacing: 1px;
    }
    .sub-main-w3 label i {
        font-size: 15px;
        margin-left: 5px;
        color: #5a4149;
        border-radius: 50%;
        line-height: 1.9;
        text-align: center;
    }
    .form-style-agile input[type="text"], .form-style-agile input[type="password"] {
        width: 100%;
        color: #000;
        outline: none;
        font-size: 14px;
        letter-spacing: 1px;
        padding: 15px 15px;
        box-sizing: border-box;
        border: none;
        border: 1px solid #000;
        background: #fff;
    }
    .sub-main-w3 input[type="button"] {
        color: #fff;
        background: #8b7780;
        border: none;
        padding: 13px 0;
        margin-top: 30px;
        outline: none;
        width: 36%;
        font-size: 16px;
        cursor: pointer;
        letter-spacing: 2px;
        -webkit-transition: 0.5s all;
        -o-transition: 0.5s all;
        -moz-transition: 0.5s all;
        -ms-transition: 0.5s all;
        transition: 0.5s all;
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.49);
    }
    .sub-main-w3 input[type="submit"]:hover {
        background: #000;
        -webkit-transition: 0.5s all;
        -o-transition: 0.5s all;
        -moz-transition: 0.5s all;
        -ms-transition: 0.5s all;
        transition: 0.5s all;
    }
    .wthree-text {
        width: 100%;
    }
    .wthree-text ul li:nth-child(1) {
        float: left;
    }
    .wthree-text ul li:nth-child(2) {
        float: right;
    }
    .wthree-text ul li {
        display: inline-block;
    }
    .wthree-text ul li a {
        color: #fff;
        font-size: 14px;
        letter-spacing: 1px;
        font-weight: 500;
    }
    .wthree-text label {
        font-size: 15px;
        color: #fff;
        cursor: pointer;
        position: relative;
    }
    .wthree-text {
        text-align: center;
    }
    input.checkbox {
        background: #f7296f;
        cursor: pointer;
        width: 1.2em;
        height: 1.2em;
        vertical-align: text-bottom;
    }
    input.checkbox:before {
        content: "";
        position: absolute;
        width: 1.2em;
        height: 1.2em;
        background: inherit;
        cursor: pointer;
    }
    input.checkbox:after {
        content: "";
        position: absolute;
        top: 4px;
        left: 4px;
        z-index: 1;
        width: 1em;
        height: 1em;
        border: 1px solid #fff;
        -webkit-transition: .4s ease-in-out;
        -moz-transition: .4s ease-in-out;
        -o-transition: .4s ease-in-out;
        transition: .4s ease-in-out;
    }
    input.checkbox:checked:after {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
        height: .5rem;
        border-color: #fff;
        border-top-color: transparent;
        border-right-color: transparent;
    }
    .anim input.checkbox:checked:after {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
        height: .5rem;
        border-color: transparent;
        border-right-color: transparent;
        animation: .4s rippling .4s ease;
        animation-fill-mode: forwards;
    }
    @keyframes rippling {
        50% {
            border-left-color: #fff;
        }
        100% {
            border-bottom-color: #fff;
            border-left-color: #fff;
        }
    }

    ::-webkit-input-placeholder {
        color: #b8b8b8;
    }

    :-moz-placeholder {
        color: #b8b8b8;
    }

    ::-moz-placeholder {
        color: #b8b8b8;
    }

    :-ms-input-placeholder {
        color: #b8b8b8;
    }
    .footer {
        margin: 4vw .3vw 2vw;
        text-align: center;
        color: white;
    }
    .footer p {
        font-size: 14px;
        color: #fff;
        letter-spacing: 2px;
        text-align: center;
        line-height: 1.8;
    }
    .footer p a {
        color: #fff;
        -webkit-transition: 0.5s all;
        -o-transition: 0.5s all;
        -moz-transition: 0.5s all;
        -ms-transition: 0.5s all;
        transition: 0.5s all;
    }
    .footer p a:hover {
        color: #f7296f;
        -webkit-transition: 0.5s all;
        -o-transition: 0.5s all;
        -moz-transition: 0.5s all;
        -ms-transition: 0.5s all;
        transition: 0.5s all;
    }
    #bg {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: #42586b
    }
    #bg canvas {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    /*--//responsive--*/

</style>
